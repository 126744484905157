// Responsive breakpoints managar
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Breakpoints
$breakpoints: (
  "xs": 576px,
  "sm": 768px,
  "md": 992px,
  "lg": 1200px,
);

// Colors
$primary: #1d2a59;
$border-color: #1d2a59;
$white: #fff;

// Text colors
$text-primary: #1d2a59;
$text-secondary: rgba(#1d2a59, 0.7);
$text-lightgray: #cdcdcd;

// Fonts
$body-font: "Helvetica Neue", "HelveticaNeue-Light", "Helvetica Neue Light",
  Helvetica, Arial, "Lucida Grande", sans-serif;
$headings-font: "Petersburg";
