@import "../../styles/variables";

.lostPassword {
  @include respond-to("md") {
    padding: 0 15px;
  }

  h1 {
    margin-top: 20px;
  }

  .row {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond-to("md") {
      margin-top: 10px;
    }

    .col {
      width: 45%;

      @include respond-to("md") {
        margin-top: 20px;
        width: 100%;
      }

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 700;
      }
    }

    .error {
      width: 100%;
      display: block;
      margin-top: 5px;
      color: red;
    }
  }

  button {
    margin-top: 25px;
    width: 45%;

    @include respond-to("md") {
      margin-top: 20px;
      width: 100%;
    }
  }
}

.modalContent {
  padding: 30px 50px;
  text-align: center;

  p {
    display: block;
    margin-top: 20px;
    margin-bottom: 25px;
  }
}
