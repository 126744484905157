@import "../../styles/variables";

.login {
  @include respond-to("md") {
    padding: 0 15px;
  }

  h1 {
    margin-top: 20px;
  }

  .row {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond-to("md") {
      margin-top: 10px;
    }

    .col {
      width: 45%;

      @include respond-to("md") {
        margin-top: 20px;
        width: 100%;
      }

      label {
        display: block;
        margin-bottom: 5px;
      }
    }

    .error {
      width: 100%;
      display: block;
      margin-top: 5px;
      color: red;
    }

    .lostPassword {
      width: 100%;
      margin-top: 25px;

      a {
        text-decoration: underline;
        color: $text-primary;
      }
    }

    button {
      margin-top: 15px;
      width: 45%;

      @include respond-to("md") {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
