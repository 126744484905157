@import "../../../styles/variables";

.spinner {
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid $primary;
  border-right: 3px solid $primary;
  border-left: 3px solid $primary;
  width: 28px;
  height: 28px;
  -webkit-animation: spin 0.7s linear infinite; /* Safari */
  animation: spin 0.7s linear infinite;

  &.white {
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-left: 3px solid #fff;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
