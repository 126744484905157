@import "../../styles/variables";

.homeProds {
  margin-top: 80px;

  @include respond-to("md") {
    padding: 0 15px;
  }

  .homeTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    @include respond-to("md") {
      flex-wrap: wrap;
    }

    .titleLeft {
      h3 {
        text-transform: uppercase;
        font-size: 60px;
        margin-bottom: 10px;

        @include respond-to("md") {
          font-size: 48px;
        }

        @include respond-to("xs") {
          font-size: 38px;
          margin-bottom: 10px;
        }

        span {
          color: $primary;
        }
      }

      @include respond-to("xs") {
        margin-bottom: 10px;
      }
    }

    a {
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
      color: $primary;
    }
  }

  .collWrap {
    margin-bottom: 60px;

    .appTitle {
      .appTitleCont {
        display: flex;
        align-items: center;

        img {
          margin-left: 15px;
        }
      }
      .downloadC {
        margin-left: 32px;
        margin-top: 7px;
        display: inline-block;
        color: $primary;
        font-weight: 500;
        font-size: 17px;
      }
    }

    h5 {
      font-size: 24px;
      text-transform: uppercase;

      &:before {
        content: "";
        display: inline-block;
        margin-right: 15px;
        width: 15px;
        height: 15px;
        background-color: $primary;
      }
    }

    .themeRow {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      .themeCol {
        margin-top: 30px;
        width: 33.333%;
        padding: 0 20px;
        text-align: center;

        @include respond-to("md") {
          width: 100%;
        }

        .themeContent {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 15px;
          padding: 30px 30px 0 30px;
          background-color: #f5f5f5;
          box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.2);

          .prodWrap {
            position: relative;
            width: calc(50% - 15px);
            height: 120px;
            margin-bottom: 30px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              left: 0;
              top: 0;
            }

            .prodCode {
              position: absolute;
              top: 15px;
              left: 15px;
              color: #fff;
              font-size: 12px;
            }

            .prodName {
              position: absolute;
              bottom: 0;
              left: 0;
              padding: 0 15px 15px 15px;
              text-align: left;
              color: #fff;
              font-size: 15px;
              font-family: "Petersburg";
            }
          }
        }

        h6 {
          color: $primary;
          text-transform: uppercase;
          font-size: 15px;
        }
      }
    }
  }
}
