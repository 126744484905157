@import "../../styles/variables";

.realtedProds {
  margin-top: 50px;

  h3 {
    font-size: 22px;

    &:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      background-color: $primary;
    }
  }

  .relProd {
    margin-top: 20px;
    display: flex;

    .left {
      flex: 0 0 120px;
    }

    .right {
      margin-left: 20px;

      h4 {
        margin-bottom: 10px;
        font-size: 20px;

        a {
          color: $primary;
        }
      }

      .code {
        font-size: 16px;
        color: rgba($primary, 0.7);
      }

      .relTagWrap {
        margin-top: 20px;

        li {
          display: inline-block;
          font-style: italic;
          margin-right: 5px;
          margin-bottom: 5px;
          color: rgba($primary, 0.6);
        }
      }
    }
  }
}
