@import "../../styles/variables";

.registration {
  margin-bottom: 30px;

  @include respond-to("md") {
    padding: 0 15px;
  }

  h1 {
    margin-top: 20px;
  }

  h3 {
    margin-top: 35px;
    margin-bottom: 15px;
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    @include respond-to("xs") {
      margin-left: -7px;
      margin-right: -7px;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }

    .col {
      width: 33%;
      padding: 0 15px;

      @include respond-to("md") {
        margin-top: 15px;
        width: 100%;
      }
      @include respond-to("xs") {
        padding: 0 7px;
      }
    }

    .colSmall {
      margin-top: 20px;
      width: 25%;
      padding: 0 15px;

      @include respond-to("md") {
        margin-top: 15px;
        width: 50%;
      }
      @include respond-to("xs") {
        padding: 0 7px;
      }
    }

    .colPrivacy {
      width: 100;
      margin-top: 30px;
      padding: 0 10px;
      line-height: 1.5;
      display: flex;
      align-items: center;

      label {
        padding-left: 10px;
      }

      label,
      input {
        cursor: pointer;
      }
    }
  }

  .error {
    display: block;
    margin-top: 5px;
    color: red;
  }

  .errorWrap {
    width: 100%;
    margin-bottom: -25px;
    margin-top: 25px;
  }

  .submitCol {
    margin-top: 50px;
    width: calc(50% - 15px);

    @include respond-to("md") {
      margin-top: 30px;
      width: 100%;
    }

    button {
      width: 100%;
    }

    p {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

.modalContent {
  text-align: center;
  padding: 20px;

  h3 {
    line-height: 1.3;
  }

  p {
    font-size: 18px;
    margin-bottom: 35px;
  }

  button {
    width: 100%;
  }
}
