@import "../../styles/variables";

.box {
  margin-bottom: 15px;
  background-color: #f5f5f5;

  .boxTitle {
    padding: 7px 7px 7px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      color: rgba($primary, 0.7);
      font-size: 16px;
      text-transform: uppercase;
    }

    .btnToggle {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .boxBody {
    padding: 10px 35px;
  }
}
