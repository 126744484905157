@import "../../../styles/variables";

.lbContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  z-index: 1000;

  .lbClose {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    cursor: pointer;
    z-index: 1;

    img {
      width: 32px;
    }
  }

  .lbPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: auto;
      max-width: 90%;
      height: 96%;
      object-fit: cover;

      @include respond-to("md") {
        height: 70%;
      }
    }
  }
}