@import "../../styles/variables";

.featuredWrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  @include respond-to("md") {
    margin-left: 0;
    margin-right: 0;
  }

  .col {
    padding: 0 15px;
  }

  .col1 {
    width: 30%;

    @include respond-to("md") {
      width: 50%;
    }
    @include respond-to("xs") {
      width: 100%;
    }

    .blueBox {
      position: relative;
      padding-right: 25px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      width: 100%;
      height: 340px;
      background-color: $primary;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      @include respond-to("xs") {
        margin-bottom: 15px;
      }

      .blueBoxContent {
        text-align: right;

        h4 {
          text-transform: uppercase;
          font-size: 44px;
          color: #fff;
        }

        a {
          text-transform: uppercase;
          color: #fff;
          font-size: 21px;
          text-decoration: underline;
        }
      }
    }

    .prodWrap {
      display: flex;
      justify-content: space-between;

      .prodBox {
        display: block;
        width: calc(50% - 15px);
        height: 160px;

        @include respond-to("xs") {
          width: calc(50% - 7px);
        }

        .prodContent {
          padding: 12px;

          span {
            font-size: 10px;
          }
          h4 {
            margin-top: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .col2 {
    width: 45%;

    @include respond-to("md") {
      width: 50%;
    }
    @include respond-to("xs") {
      width: 100%;
      margin-top: 15px;
    }

    .prodBox {
      height: 530px;

      @include respond-to("xs") {
        height: 340px;
      }
    }
  }

  .col3 {
    width: 25%;

    @include respond-to("md") {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    @include respond-to("xs") {
      margin-top: 15px;
    }

    .prodBox {
      height: 250px;

      @include respond-to("md") {
        width: calc(50% - 15px);
      }
      @include respond-to("xs") {
        width: calc(50% - 7px);
        height: 160px;
      }

      .prodContent {
        padding: 20px;

        span {
          font-size: 14px;
        }
        h4 {
          font-size: 16px;
        }
      }

      &:first-child {
        margin-bottom: 30px;
      }
    }
  }

  .prodBox {
    display: block;
    position: relative;

    &:before {
      content: "";
      background-image: url("../../assets/images/short-logo.svg");
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      background-size: 50px;
      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .prodContent {
      width: 100%;
      color: #fff;
      padding: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      span {
        text-transform: uppercase;
        font-size: 18px;
      }

      h4 {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }
}
