@import "../../styles/variables";

.footer {
  margin-top: 50px;
  padding: 40px 0;
  flex-shrink: 0;
  background-color: $primary;

  @include respond-to("md") {
    padding: 40px 15px;
  }

  .logo {
    width: 250px;
    height: auto;
    margin-bottom: 50px;
  }

  .row {
    display: flex;
    justify-content: space-between;

    @include respond-to("md") {
      flex-wrap: wrap;
    }

    .sub {
      margin-top: 40px;
    }
  }

  .col {
    @include respond-to("md") {
      width: 100%;
      margin-top: 30px;
    }
    .link {
      margin-bottom: 20px;

      a {
        text-transform: uppercase;
        color: #fff;
      }
    }

    .grey {
      color: #aaa;
      font-size: 10px;
    }

    span {
      font-size: 15px;
      margin-bottom: 10px;
      color: #fff;
      display: block;
      cursor: pointer;
    }
  }
}
