@import "../../styles/variables";

.searchWrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond-to("md") {
    padding: 0 15px;
  }

  .filterIcon {
    position: absolute;
    top: -52px;
    right: 15px;
    display: none;
    width: 48px;
    height: 38px;
    justify-content: center;
    align-items: center;

    img {
      width: 23px;
    }

    @include respond-to("md") {
      display: flex;
    }
  }

  .sidebar {
    width: calc(30% - 15px);

    @include respond-to("md") {
      width: 100%;
      margin-bottom: 30px;
    }

    &.open {
      display: block;
    }

    &.close {
      display: block;

      @include respond-to("md") {
        display: none;
      }
    }

    .checkWrap {
      display: block;
      position: relative;
      padding-left: 25px;
      margin-bottom: 12px;
      font-size: 15px;
      cursor: pointer;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }

      input {
        position: absolute;
        opacity: 0;
        width: 0;

        &:checked ~ .checkMark:after {
          display: block;
          content: "";
        }

        &:hover ~ .checkMark {
          background-color: rgba($primary, 0.1);
        }
      }

      .checkMark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border: 2px solid $primary;
        background-color: #f5f5f5;

        &:after {
          position: absolute;
          display: none;
          top: 2px;
          left: 2px;
          width: 10px;
          height: 10px;
          background-color: $primary;
        }

        &.radioMark,
        &.radioMark:after {
          border-radius: 50%;
        }
      }
    }

    .resetFilter {
      color: #de2121;
      cursor: pointer;
      text-transform: uppercase;
      display: block;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .results {
    width: calc(70% - 15px);

    @include respond-to("md") {
      width: 100%;
    }

    h1 {
      margin-bottom: 30px;
    }

    .prodsRow {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;

      .prodCol {
        width: 33.333%;
        padding: 0 15px;
        margin-bottom: 30px;
        display: flex;

        @include respond-to("md") {
          width: 50%;
          margin-bottom: 30px;
        }
        @include respond-to("xs") {
          width: 100%;
        }

        .prodContent {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          position: relative;
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);

          .btnAdd {
            position: absolute;
            top: 15px;
            left: 15px;
            cursor: pointer;
          }

          .prodMood {
            position: absolute;
            top: 185px;
            left: 15px;
            color: #fff;
            font-size: 16px;
          }

          .prodCode {
            position: absolute;
            top: 210px;
            left: 15px;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
          }

          .prodImage {
            height: 250px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .prodInfo {
            flex: 1;
            background-color: #f5f5f5;
            padding: 20px 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 a {
              color: $primary;
            }

            strong {
              margin-top: 20px;
              display: inline-block;
              color: #aaaaaa;
            }

            .availability {
              color: $primary;
              font-size: 10px;
              font-weight: 700;
              margin-top: 10px;
              margin-bottom: -10px;
            }

            .prodTags {
              margin-top: 3px;
              margin-bottom: 25px;

              span {
                color: #aaaaaa;
                font-size: 12px;
                font-style: italic;
                display: inline-block;
                margin-right: 5px;
                margin-top: 7px;
              }
            }

            .prodMore {
              text-transform: uppercase;
              font-size: 15px;
              font-weight: 700;
              text-decoration: underline;
              color: $primary;
            }
          }
        }
      }

      .pagination {
        margin-top: 40px;
        width: 100%;
        display: flex;
        padding: 0 5px;
        justify-content: flex-end;

        .page {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
          color: $primary;
          cursor: pointer;
          margin-left: 10px;
          font-size: 18px;
          font-weight: 700;

          &.current {
            background-color: $primary;
            color: #fff;
            cursor: default;
          }
        }
      }
    }

    .noRes {
      margin-top: 15px;
    }
  }
}
