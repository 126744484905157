@import "../../styles/variables";

.header {
  padding: 25px 0;
  background-color: $primary;
  margin-bottom: 50px;

  @include respond-to("md") {
    padding: 10px 0;
    margin-bottom: 65px;
  }

  .headerContent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-to("md") {
      padding: 0 15px;
    }

    .rightSide {
      display: flex;

      .searchWrap {
        display: flex;

        @include respond-to("md") {
          position: absolute;
          left: 0;
          top: 65px;
          width: calc(100% - 30px);
          margin: 0 15px;
        }

        input {
          width: 230px;
          height: 38px;
          background-color: rgba(#fff, 0.13);
          color: #fff;
          padding: 0 10px;
          border: 1px solid #fff;

          @include respond-to("md") {
            width: 100%;
            background-color: #e7e7e7;
            border: 1px solid #e7e7e7;
            color: $text-primary;
          }
        }

        button {
          width: 38px;
          height: 38px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #fff;
          outline: none;
          cursor: pointer;

          .mob {
            display: none;

            @include respond-to("md") {
              display: block;
            }
          }

          .desk {
            display: block;

            @include respond-to("md") {
              display: none;
            }
          }

          @include respond-to("md") {
            background-color: #e7e7e7;
            border: 1px solid #e7e7e7;
          }
        }
      }

      .box {
        display: flex;
        align-items: center;
        margin-left: 25px;

        span {
          color: $white;
          margin-left: 10px;

          @include respond-to("md") {
            display: none;
          }
        }
      }
    }
  }
}
