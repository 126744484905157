@font-face {
  font-family: "Petersburg";
  font-weight: 400;
  src: local("Petersburg"),
    url("./../assets/fonts/Petersburg\ Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Petersburg";
  font-weight: 700;
  src: local("Petersburg"),
    url("./../assets/fonts/Petersburg\ Bold\ Cyrillic.ttf") format("ttf");
}
