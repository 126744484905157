@import "../../styles/variables";

.wishlist {
  @include respond-to("md") {
    padding: 0 15px;
  }

  .subtitle {
    margin-top: 10px;
  }

  .noProd {
    display: block;
    margin-top: 10px;
    font-size: 16px;
  }

  .prodRow {
    margin-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    .prodCol {
      width: 50%;
      padding: 0 15px;

      @include respond-to("xs") {
        width: 100%;
      }

      .prodWrap {
        display: flex;
        align-items: flex-start;
        padding-bottom: 20px;
        border-bottom: 1px solid $primary;
        margin-bottom: 30px;

        & > img {
          width: 130px;
          height: 130px;
          object-fit: cover;
          margin-right: 20px;
        }

        .prodInfo {
          width: 100%;
          position: relative;

          .remove {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            background-color: $primary;
          }

          h3 {
            margin-bottom: 8px;
            color: $primary;
            padding-right: 30px;
          }

          span {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 15px;
            opacity: 0.6;
          }

          .availability {
            display: block;
            font-style: italic;
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: -10px;
          }

          ul {
            margin-top: 20px;

            li {
              display: inline-block;
              color: rgba($primary, 0.7);
              margin-right: 5px;
              line-height: 1.3;
            }
          }
        }
      }
    }
  }

  .ctaCont {
    display: flex;

    @include respond-to("md") {
      flex-wrap: wrap;
    }

    .ctaWrap {
      margin-right: 15px;

      @include respond-to("md") {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
      }
    }

    @include respond-to("md") {
      .ctaWrapP {
        width: 100%;
      }
    }
  }
}
