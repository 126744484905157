@import "../../styles/variables";

.profile {
  margin-bottom: 60px;

  @include respond-to("md") {
    padding: 0 15px;
  }

  .profileWrap {
    position: relative;
  }

  .logoutWrite {
    font-size: 16px;
  }

  .logoutButton {
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
  }

  .abSpinner {
    position: absolute;
    top: 10px;
    right: 0;
  }

  h1 {
    margin-top: 20px;
  }

  h3 {
    margin-top: 35px;
    margin-bottom: 15px;
    color: $primary;

    @include respond-to("md") {
      margin-bottom: 5px;
    }
  }

  .editBtn,
  .saveBtn {
    cursor: pointer;
    position: absolute;
    top: 35px;
    right: 0;
  }

  .saveBtn {
    outline: none;
    background-color: transparent;
    border: none;
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    @include respond-to("xs") {
      margin-left: -7px;
      margin-right: -7px;
    }

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: 700;
      order: 0;
      padding-left: 15px;
    }

    input {
      order: 1;
    }

    input:disabled {
      background-color: #f5f5f5;
    }

    .col {
      width: 33.333%;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include respond-to("md") {
        margin-top: 15px;
        width: 100%;
      }
      @include respond-to("xs") {
        padding: 0 7px;
      }
    }

    .colSmall {
      margin-top: 20px;
      width: 25%;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include respond-to("md") {
        width: 50%;
      }
      @include respond-to("xs") {
        padding: 0 7px;
      }
    }
  }

  .error {
    display: block;
    margin-top: 5px;
    color: red;
    order: 2;
  }

  .errorWrap {
    width: 100%;
    margin-bottom: -25px;
    margin-top: 25px;
  }

  .submitCol {
    margin-top: 50px;
    width: calc(50% - 15px);

    button {
      width: 100%;
    }

    p {
      margin-top: 10px;
      font-size: 12px;
    }
  }

  .changePswButton {
    margin-top: 30px;
  }

  .modalContent {
    text-align: center;
    padding: 50px 20px;

    h3 {
      color: $text-primary;
      line-height: 1.3;
      margin-top: 0;
      margin-bottom: 25px;
    }

    p {
      font-size: 18px;
      margin-bottom: 35px;
    }

    .cancelChangePassword {
      cursor: pointer;
      display: block;
      margin-bottom: 20px;
      font-weight: 700;
      color: #721919;
      font-size: 18px;
      text-decoration: underline;
    }

    .row {
      text-align: left;

      .col {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .errorMsg {
      text-align: left;
      color: red;
      margin-bottom: 20px;
      display: block;
    }

    button {
      width: 100%;
    }
  }
}
