@import "../../styles/variables";

.orderForm {
  h3 {
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 15px;

    .col {
      padding: 0 15px;

      label {
        display: block;
        margin-bottom: 5px;
      }

      input:disabled {
        background-color: #f5f5f5;
      }
    }

    .col50 {
      width: 50%;

      @include respond-to("xs") {
        width: 100%;
      }
    }

    .col100 {
      width: 100%;
    }
  }

  .error {
    color: red;
    display: block;
    margin-top: 5px;
  }

  .ctaWrap {
    margin-top: 35px;
    margin-bottom: 15px;
    text-align: center;

    span {
      cursor: pointer;
      display: block;
      margin-bottom: 25px;
      font-weight: 700;
      color: #721919;
      font-size: 18px;
      text-decoration: underline;
    }
  }

  .lastMessage {
    font-style: italic;
    text-align: center;
    font-size: 12px;
    margin-bottom: 40px;
  }
}

.orderCompleted {
  text-align: center;
  padding: 40px 20px;

  h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    line-height: 1.3;
  }

  p {
    display: block;
    margin-bottom: 30px;
    font-size: 18px;
  }
}
