@import "../../styles/variables";

.eModal {
  position: fixed;
  padding-top: 40px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;

  .eModalContent {
    position: relative;
    overflow: auto;
    width: 400px;
    max-height: calc(100% - 20px);
    max-width: calc(100% - 20px);
    padding: 30px;
    background-color: #fff;
    box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.3);

    .eModalClose {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 25px;
      height: 25px;
      background-color: $primary;
      color: #fff;
      line-height: 25px;
      text-align: center;
      cursor: pointer;
    }

    span {
      font-size: 16px;
    }
  }
}
