@import "../../../styles/variables";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;

  .modalContent {
    overflow: auto;
    width: 700px;
    max-height: calc(100% - 20px);
    max-width: calc(100% - 20px);
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.3);
  }
}
