@import "../../../styles/variables";

.button {
  outline: none;
  appearance: none;
  cursor: pointer;
  height: 58px;
  line-height: 54px;
  font-size: 18px;
  font-family: $body-font;
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.primary {
    color: #fff;
    border: 1px solid $primary;
    background-color: $primary;
  }

  &.secondary {
    color: $text-primary;
    border: rgba(170, 170, 170, 0.2);
    background-color: rgba(170, 170, 170, 0.2);
  }

  &.fullwidth {
    width: 100%;
  }
}
