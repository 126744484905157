@import "./styles/variables";
@import "./styles/reset";
@import "./styles/fonts";
@import "./styles/helpers";

html,
body {
  height: 100%;
}

body {
  font-family: $body-font;
  color: $text-primary;
  color: $text-primary;
  font-size: 14px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font;
  font-weight: 700;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 22px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

p {
  line-height: 1.5;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"] {
  height: 42px;
  line-height: 42px;
  outline: none;
  appearance: none;
  font-size: 16px;
  font-weight: 700;
  font-family: $body-font;
  padding: 0 20px;
  border: 1px solid $primary;
  width: 100%;
  color: $text-primary;
}

textarea {
  outline: none;
  appearance: none;
  font-size: 16px;
  resize: none;
  font-weight: 700;
  font-family: $body-font;
  padding: 20px;
  border: 1px solid $primary;
  width: 100%;
  color: $text-primary;
}

ul {
  list-style-type: none;
}

.wrapper {
  flex: 1 0 auto;
}

.simple-link {
  color: $primary;
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}
