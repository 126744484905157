@import "../../styles/variables";

.product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond-to("md") {
    padding: 0 15px;
  }

  .leftCol {
    width: 47%;

    @include respond-to("md") {
      width: 100%;
    }

    .prodImage {
      position: relative;

      img {
        cursor: pointer;
        width: 100%;
      }

      .addToList {
        display: flex;
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        left: 20px;

        img {
          width: 28px;
        }

        strong {
          display: block;
          margin-left: 10px;
          width: 100px;
          color: #fff;
          text-transform: uppercase;
          font-size: 15px;
          line-height: 1.2;
        }
      }
    }

    .prodGallery {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;

      @include respond-to("md") {
        margin: -7px;
      }

      .photoCol {
        width: 16.666%;
        padding: 0 12px;
        margin-top: 30px;

        @include respond-to("md") {
          width: 25%;
          padding: 0 7px;
        }

        span {
          display: block;
          font-size: 13px;
          font-weight: 700;
          margin-top: 5px;
        }
      }
    }
  }

  .rightCol {
    width: 47%;

    @include respond-to("md") {
      margin-top: 30px;
      width: 100%;
    }

    .prodTitle {
      display: flex;
      margin-bottom: 20px;

      img {
        width: 85px;
        height: 85px;

        @include respond-to("md") {
          display: none;
        }
      }

      .titleContent {
        margin-left: 20px;

        @include respond-to("md") {
          margin-left: 0;
        }

        h5 {
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }

        h1 {
          font-size: 37px;
          margin-bottom: 5px;

          @include respond-to("xs") {
            font-size: 32px;
          }
        }

        span {
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }

    .prodDescription {
      font-size: 18px;
    }

    .availability {
      font-size: 16px;
      font-style: italic;
      display: block;
      margin-top: 30px;
      margin-bottom: -20px;
    }

    .prodInfo {
      margin-top: 50px;

      h4 {
        font-size: 22px;
      }

      .infoList {
        margin-top: 20px;

        li {
          display: flex;
          font-size: 18px;
          padding-bottom: 7px;
          border-bottom: 1px solid rgba($primary, 0.5);
          margin-bottom: 15px;

          .comp {
            margin-right: 7px;
          }

          .first {
            display: inline-block;
            flex: 0 0 200px;
          }
        }
      }
    }

    .tagWrap {
      margin-top: 45px;

      span {
        display: inline-block;
        margin-right: 17px;
        font-size: 18px;
        line-height: 30px;
        color: rgba($primary, 0.7);
      }
    }

    .addButton {
      margin-top: 45px;

      p {
        margin-top: 10px;

        a {
          font-weight: 700;
          text-decoration: underline;
          color: $primary;
        }
      }
    }
  }
}

.spinnerWrap {
  margin-top: 40px;
}
